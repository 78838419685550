<template>
  <b-row>
    <!-- Empresa Asociada -->
    <b-col cols="12">
      <b-form-group label="Store" label-for="v-store">
        <v-select id="vi-store" label="nombre" v-model="reporte.IDEmpresaAsociada" :reduce="(tienda) => tienda.id"
          :options="tiendas" :loading="loadingTiendas" :clearable="false" :filterable="true"
          @option:selected="onChangeReport" />
      </b-form-group>
    </b-col>

    <!-- Factura -->
    <b-col cols="12">
      <b-form-group>
        <b-form-checkbox switch inline @input="onChangeReport" v-model="reporte.factura">
          Factura
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Fecha inicio -->
    <b-col cols="12">
      <b-form-group label="Fecha inicio" label-for="v-fecha-ini">
        <b-form-datepicker id="v-fecha-ini" @input="onChangeReport" v-model="reporte.fechaInicio" :date-format-options="{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }" />
      </b-form-group>
    </b-col>

    <!-- Fecha fin -->
    <b-col cols="12">
      <b-form-group label="Fecha fin" label-for="v-fecha-fin">
        <b-form-datepicker id="v-fecha-fin" :min="reporte.fechaInicio" @input="onChangeReport"
          v-model="reporte.fechaFin" :date-format-options="{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }" />
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-1">
      <b-button block @click="generateReport" variant="primary" :disabled="loading || url !== null">
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Generar reporte</div>
      </b-button>
    </b-col>

    <b-col v-if="url" cols="12" class="mt-1">
      <b-link target="_blank" :href="url">Descargar reporte</b-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    vSelect,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
  },
  data() {
    return {
      url: null,
      tiendas: [],
      reporte: null,
      loading: false,
      loadingTiendas: false,
    };
  },
  beforeMount() {
    this.reporte = {
      factura: false,
      fechaFin: null,
      fechaInicio: null,
      IDEmpresaAsociada: null,
    };
  },
  mounted() {
    this.getTiendasData();
  },
  methods: {
    onChangeReport() {
      this.url = null;
    },
    getTiendasData() {
      this.loadingTiendas = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetStores",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            res.data.stores.unshift({
              id: 0,
              nombre: "Todas",
            });
            this.tiendas = res.data.stores;
            this.$nextTick(() => {
              this.reporte.IDEmpresaAsociada = 0;
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingTiendas = false;
        });
    },
    generateReport(event) {
      event.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Reportes/OrdenesAnuladas",
          body: JSON.stringify({
            ...this.reporte,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            if (res.data.url === null) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "El reporte seleccionado no genero datos, intente con otro rango de fechas",
                  title: "Advertencia",
                  variant: "warning",
                },
              });
            } else {
              this.url = res.data.url;
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se genero correctamente el Reporte.",
                  title: "Exito",
                  variant: "success",
                },
              });
            }
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al generar el Reporte [${e.message}].`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>