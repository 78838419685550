<template>
  <b-row>
    <!-- Empresa Asociada -->
    <b-col cols="12">
      <b-form-group label="Store" label-for="v-store">
        <v-select id="vi-store" label="nombre" v-model="reporte.IDEmpresaAsociada" :reduce="(tienda) => tienda.id"
          :options="tiendas" :loading="loadingTiendas" :clearable="false" :filterable="true"
          @option:selected="onChangeReport" />
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-1">
      <b-button block @click="generateReport" variant="primary" :disabled="loading || url !== null">
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Generar reporte</div>
      </b-button>
    </b-col>

    <b-col v-if="url" cols="12" class="mt-1">
      <b-link target="_blank" :href="url">Descargar reporte</b-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuthToken, getRoleUser } from "@/auth/utils";
import _ from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    vSelect,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
  },
  data() {
    return {
      url: null,
      tiendas: [],
      reporte: null,
      loading: false,
      loadingTiendas: true,
      rol: ''
    };
  },
  beforeMount() {
    this.rol = getRoleUser()
    this.reporte = {
      IDEmpresaAsociada: null,
    };
  },
  mounted() {
    this.getTiendasData();
  },
  methods: {
    onChangeReport() {
      this.url = null;
    },
    getTiendasData() {
      this.loadingTiendas = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UserStores",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.tiendas = res.data.lsTiendas;
            if (res.data.lsTiendas.length > 0)
              this.$nextTick(() => {
                this.reporte.IDEmpresaAsociada = this.tiendas[0].id;
              });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingTiendas = false;
        });
    },
    generateReport(event) {
      event.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Reportes/CatalogoTienda",
          body: JSON.stringify({
            ...this.reporte,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            if (res.data.url === null)
              throw new Error(
                "El reporte seleccionado no genero datos, intente con otra configuración"
              );
            this.url = res.data.url;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se genero correctamente el Reporte.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al generar el Reporte [${e.message}].`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
