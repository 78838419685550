var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading || _vm.url !== null
    },
    on: {
      "click": _vm.generateReport
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Generar reporte")])], 1)], 1), _vm.url ? _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-link', {
    attrs: {
      "target": "_blank",
      "href": _vm.url
    }
  }, [_vm._v("Descargar reporte")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }