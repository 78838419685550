var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha inicio",
      "label-for": "v-fecha-ini"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "v-fecha-ini"
    },
    on: {
      "input": _vm.onChangeReport
    },
    model: {
      value: _vm.reporte.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.reporte, "fechaInicio", $$v);
      },
      expression: "reporte.fechaInicio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha fin",
      "label-for": "v-fecha-fin"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "v-fecha-fin",
      "min": _vm.reporte.fechaInicio
    },
    on: {
      "input": _vm.onChangeReport
    },
    model: {
      value: _vm.reporte.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.reporte, "fechaFin", $$v);
      },
      expression: "reporte.fechaFin"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading || _vm.url !== null
    },
    on: {
      "click": _vm.generateReport
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Generar reporte")])], 1)], 1), _vm.url ? _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-link', {
    attrs: {
      "target": "_blank",
      "href": _vm.url
    }
  }, [_vm._v("Descargar reporte")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }