<template>
  <b-row>
    <!-- Empresa Asociada -->
    <b-col cols="12">
      <b-form-group label="Store" label-for="v-store">
        <v-select id="vi-store" label="nombre" v-model="reporte.IDEmpresaAsociada" :reduce="(tienda) => tienda.id"
          :options="tiendas" :loading="loadingTiendas" :clearable="false" :filterable="true"
          @option:selected="onChangeReport" />
      </b-form-group>
    </b-col>

    <!-- Estado -->
    <b-col cols="12">
      <b-form-group label="Estado" label-for="v-estado">
        <v-select id="vi-estado" label="label" v-model="reporte.estatus" :reduce="(estado) => estado.value"
          :options="estados" :clearable="false" :filterable="false" @option:selected="onChangeReport" />
      </b-form-group>
    </b-col>

    <!-- Fecha inicio -->
    <b-col cols="12">
      <b-form-group label="Fecha inicio" label-for="v-fecha-ini">
        <b-form-datepicker id="v-fecha-ini" @input="onChangeReport" v-model="reporte.fechaInicio" :date-format-options="{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }" />
      </b-form-group>
    </b-col>

    <!-- Fecha fin -->
    <b-col cols="12">
      <b-form-group label="Fecha fin" label-for="v-fecha-fin">
        <b-form-datepicker id="v-fecha-fin" :min="reporte.fechaInicio" @input="onChangeReport"
          v-model="reporte.fechaFin" :date-format-options="{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }" />
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-1">
      <b-button block @click="generateReport" variant="primary" :disabled="loading || url !== null">
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Generar reporte</div>
      </b-button>
    </b-col>

    <b-col v-if="url" cols="12" class="mt-1">
      <b-link target="_blank" :href="url">Descargar reporte</b-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuthToken, getRoleUser } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    vSelect,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
  },
  data() {
    return {
      url: null,
      estados: [
        { label: "Todos", value: "" },
        { label: "Pendiente", value: "PG" },
        { label: "Creada", value: "CR" },
        { label: "Anulada", value: "AN" },
        { label: "Preparada", value: "PR" },
        { label: "Enviada", value: "EN" },
        { label: "En preparación", value: "EP" },
        { label: "LLego", value: "LL" },
        { label: "No Entregada", value: "NE" },
        { label: "Entregada", value: "ET" },
      ],
      tiendas: [],
      reporte: null,
      loading: false,
      loadingTiendas: true,
      rol: ''
    };
  },
  beforeMount() {
    this.rol = getRoleUser()
    this.reporte = {
      estatus: "",
      fechaFin: null,
      fechaInicio: null,
      IDEmpresaAsociada: null,
    };
  },
  mounted() {
    this.getTiendasData();
  },
  methods: {
    onChangeReport() {
      this.url = null;
    },
    getTiendasData() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UserStores",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            res.data.lsTiendas.unshift({
              id: 0,
              nombre: "Todas",
            });
            this.tiendas = res.data.lsTiendas;
            this.$nextTick(() => {
              this.reporte.IDEmpresaAsociada = this.tiendas[0].id;
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingTiendas = false;
        });
    },
    generateReport(event) {
      event.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Reportes/SeguimientoVentasV2",
          body: JSON.stringify({
            ...this.reporte,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            if (res.data.url === null)
              throw new Error(
                "El reporte seleccionado no genero datos, intente con otra configuración"
              );
            this.url = res.data.url;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se generó correctamente el Reporte.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al generar el Reporte [${e.message}].`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>