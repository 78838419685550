var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "v-tipos",
      "buttons": "",
      "options": _vm.tipos,
      "button-variant": "outline-primary"
    },
    on: {
      "input": _vm.onChangeType
    },
    model: {
      value: _vm.reporte.tipoPromo,
      callback: function callback($$v) {
        _vm.$set(_vm.reporte, "tipoPromo", $$v);
      },
      expression: "reporte.tipoPromo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha inicio",
      "label-for": "v-fecha-ini"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "v-fecha-ini"
    },
    on: {
      "input": _vm.onChangeReport
    },
    model: {
      value: _vm.reporte.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.reporte, "fechaInicio", $$v);
      },
      expression: "reporte.fechaInicio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha fin",
      "label-for": "v-fecha-fin"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "v-fecha-fin",
      "min": _vm.reporte.fechaInicio
    },
    on: {
      "input": _vm.onChangeReport
    },
    model: {
      value: _vm.reporte.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.reporte, "fechaFin", $$v);
      },
      expression: "reporte.fechaFin"
    }
  })], 1)], 1), _vm.reporte.tipoPromo === 'PROCO' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Promocode",
      "label-for": "v-promocode"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-promocode",
      "placeholder": "Promocode"
    },
    on: {
      "input": _vm.onChangeReport
    },
    model: {
      value: _vm.reporte.promocode,
      callback: function callback($$v) {
        _vm.$set(_vm.reporte, "promocode", $$v);
      },
      expression: "reporte.promocode"
    }
  })], 1)], 1) : _vm._e(), _vm.loadingBines && _vm.reporte.tipoPromo === 'PROTA' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  })], 1) : _vm._e(), !_vm.loadingBines && _vm.reporte.tipoPromo === 'PROTA' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Banco",
      "label-for": "v-banco"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-banco",
      "label": "title",
      "reduce": function reduce(b) {
        return b.title;
      },
      "options": _vm.bancos,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onchangeBanco
    },
    model: {
      value: _vm.banco,
      callback: function callback($$v) {
        _vm.banco = $$v;
      },
      expression: "banco"
    }
  })], 1), _vm.banco ? _c('b-form-group', {
    attrs: {
      "label": "Tarjeta",
      "label-for": "v-tarjeta"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-tarjeta",
      "label": "title",
      "reduce": function reduce(t) {
        return t.title;
      },
      "options": _vm.tarjetas,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onchangeTarjeta
    },
    model: {
      value: _vm.tarjeta,
      callback: function callback($$v) {
        _vm.tarjeta = $$v;
      },
      expression: "tarjeta"
    }
  })], 1) : _vm._e(), _vm.tarjeta ? _c('b-form-group', {
    attrs: {
      "label": "Bines",
      "label-for": "v-bines"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-bines",
      "label": "biBin",
      "reduce": function reduce(b) {
        return b.biBin;
      },
      "options": _vm.mBines,
      "clearable": false,
      "filterable": true
    },
    on: {
      "option:selected": _vm.onChangeReport
    },
    model: {
      value: _vm.reporte.bin,
      callback: function callback($$v) {
        _vm.$set(_vm.reporte, "bin", $$v);
      },
      expression: "reporte.bin"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.generateReport
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v(_vm._s(_vm.btnText))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }