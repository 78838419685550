<template>
    <b-row>  
      <!-- Fecha inicio -->
      <b-col cols="12">
        <b-form-group label="Fecha inicio" label-for="v-fecha-ini">
          <b-form-datepicker
            id="v-fecha-ini"
            @input="onChangeReport"
            v-model="reporte.fechaInicio"
          />
        </b-form-group>
      </b-col>
  
      <!-- Fecha fin -->
      <b-col cols="12">
        <b-form-group label="Fecha fin" label-for="v-fecha-fin">
          <b-form-datepicker
            id="v-fecha-fin"
            :min="reporte.fechaInicio"
            @input="onChangeReport"
            v-model="reporte.fechaFin"
          />
        </b-form-group>
      </b-col>
  
      <b-col cols="12" class="mt-1">
        <b-button
          block
          @click="generateReport"
          variant="primary"
          :disabled="loading || url !== null"
        >
          <b-spinner v-if="loading" small label="Loading..." />
          <div v-else>Generar reporte</div>
        </b-button>
      </b-col>
  
      <b-col v-if="url" cols="12" class="mt-1">
        <b-link target="_blank" :href="url">Descargar reporte</b-link>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BLink,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import { getAuthToken } from "@/auth/utils";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
    components: {
      BRow,
      BCol,
      BLink,
      vSelect,
      BButton,
      BSpinner,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BFormDatepicker,
    },
    data() {
      return {
        url: null,
        tiendas: [],
        reporte: null,
        loading: false,
        loadingTiendas: false,
      };
    },
    beforeMount() {
      this.reporte = {
        fechaFin: null,
        fechaInicio: null,
      };
    },
    methods: {
      onChangeReport() {
        this.url = null;
      },
      generateReport(event) {
        event.preventDefault();
        this.loading = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Reportes/ReporteOrdenesTiempo",
            body: JSON.stringify({
              ...this.reporte,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              if (res.data.url === null)
                throw new Error(
                  "El reporte seleccionado no genero datos, intente con otra configuracion"
                );
              this.url = res.data.url;
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se genero correctamente el Reporte.",
                  title: "Exito",
                  variant: "success",
                },
              });
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al generar el Reporte [${e.message}].`,
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>