<template>
  <b-row>
    <b-col cols="12" md="7">
      <b-card title="Reportes">
        <b-tabs pills vertical>
          <b-tab lazy active v-if="rol === 'SA'">
            <template #title>
              <feather-icon icon="TagIcon" />
              <span>Promociones</span>
            </template>
            <promociones />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <feather-icon icon="ShoppingCartIcon" />
              <span>Catalogo Tienda</span>
            </template>
            <catalogo />
          </b-tab>
          <b-tab lazy v-if="rol === 'SA'">
            <template #title>
              <feather-icon icon="UserXIcon" />
              <span>Ordenes Anuladas</span>
            </template>
            <anuladas />
          </b-tab>
          <b-tab lazy v-if="rol === 'SA'">
            <template #title>
              <feather-icon icon="PieChartIcon" />
              <span>Seguimiento de Ventas</span>
            </template>
            <ventas />
          </b-tab>
          <b-tab lazy>
            <template #title>
              <feather-icon icon="PieChartIcon" />
              <span>Ventas Eats Plus</span>
            </template>
            <VentasEatsPlus />
          </b-tab>
          <b-tab lazy v-if="rol === 'SA'">
            <template #title>
              <feather-icon icon="PieChartIcon" />
              <span>Ordenes tiempo</span>
            </template>
            <OrdenesTiempo />
          </b-tab>
          <b-tab lazy v-if="rol === 'SA'">
            <template #title>
              <feather-icon icon="PackageIcon" />
              <span>Catalogo Market</span>
            </template>
            <CatalogoMarket />
          </b-tab>
          <b-tab lazy v-if="rol === 'SA'">
            <template #title>
              <feather-icon icon="BoxIcon" />
              <span>Stock Productos</span>
            </template>
            <StockProductos />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
    <b-col cols="12" md="5">
      <div class="iconEmpty">
        <feather-icon icon="BookIcon" size="100" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BTab, BCard, BTabs } from "bootstrap-vue";
import Ventas from "./components/Ventas.vue";
import VentasEatsPlus from "./components/VentasEatsPlus.vue";
import Anuladas from "./components/Anuladas.vue";
import Catalogo from "./components/Catalogo.vue";
import Promociones from "./components/Promociones.vue";
import OrdenesTiempo from "./components/OrdenesTiempo.vue";
import CatalogoMarket from "./components/CatalogoMarket.vue";
import StockProductos from "./components/StockProductos.vue";
import { getRoleUser } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    Ventas,
    VentasEatsPlus,
    Anuladas,
    Catalogo,
    Promociones,
    OrdenesTiempo,
    CatalogoMarket,
    StockProductos
  },
  data() {
    return {
      rol: ''
    }
  },
  mounted() {
    this.rol = getRoleUser()
  }
};
</script>
<style>
.iconEmpty {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#v-fecha-ini__dialog_,
#v-fecha-fin__dialog_ {
  z-index: 999;
}
</style>