var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Reportes"
    }
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "vertical": ""
    }
  }, [_vm.rol === 'SA' ? _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "TagIcon"
          }
        }), _c('span', [_vm._v("Promociones")])];
      },
      proxy: true
    }], null, false, 981385428)
  }, [_c('promociones')], 1) : _vm._e(), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ShoppingCartIcon"
          }
        }), _c('span', [_vm._v("Catalogo Tienda")])];
      },
      proxy: true
    }])
  }, [_c('catalogo')], 1), _vm.rol === 'SA' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "UserXIcon"
          }
        }), _c('span', [_vm._v("Ordenes Anuladas")])];
      },
      proxy: true
    }], null, false, 1502550232)
  }, [_c('anuladas')], 1) : _vm._e(), _vm.rol === 'SA' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "PieChartIcon"
          }
        }), _c('span', [_vm._v("Seguimiento de Ventas")])];
      },
      proxy: true
    }], null, false, 3884211623)
  }, [_c('ventas')], 1) : _vm._e(), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "PieChartIcon"
          }
        }), _c('span', [_vm._v("Ventas Eats Plus")])];
      },
      proxy: true
    }])
  }, [_c('VentasEatsPlus')], 1), _vm.rol === 'SA' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "PieChartIcon"
          }
        }), _c('span', [_vm._v("Ordenes tiempo")])];
      },
      proxy: true
    }], null, false, 542032202)
  }, [_c('OrdenesTiempo')], 1) : _vm._e(), _vm.rol === 'SA' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "PackageIcon"
          }
        }), _c('span', [_vm._v("Catalogo Market")])];
      },
      proxy: true
    }], null, false, 905416694)
  }, [_c('CatalogoMarket')], 1) : _vm._e(), _vm.rol === 'SA' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "BoxIcon"
          }
        }), _c('span', [_vm._v("Stock Productos")])];
      },
      proxy: true
    }], null, false, 4113680694)
  }, [_c('StockProductos')], 1) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "iconEmpty"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookIcon",
      "size": "100"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }