<template>
  <b-row>
    <!-- Tipo -->
    <b-col cols="12">
      <b-form-group>
        <b-form-radio-group
          id="v-tipos"
          buttons
          @input="onChangeType"
          v-model="reporte.tipoPromo"
          :options="tipos"
          button-variant="outline-primary"
        />
      </b-form-group>
    </b-col>

    <!-- Fecha inicio -->
    <b-col cols="12">
      <b-form-group label="Fecha inicio" label-for="v-fecha-ini">
        <b-form-datepicker
          id="v-fecha-ini"
          @input="onChangeReport"
          v-model="reporte.fechaInicio"
        />
      </b-form-group>
    </b-col>

    <!-- Fecha fin -->
    <b-col cols="12">
      <b-form-group label="Fecha fin" label-for="v-fecha-fin">
        <b-form-datepicker
          id="v-fecha-fin"
          :min="reporte.fechaInicio"
          @input="onChangeReport"
          v-model="reporte.fechaFin"
        />
      </b-form-group>
    </b-col>

    <!-- Promocode -->
    <b-col cols="12" v-if="reporte.tipoPromo === 'PROCO'">
      <b-form-group label="Promocode" label-for="v-promocode">
        <b-form-input
          id="v-promocode"
          @input="onChangeReport"
          v-model="reporte.promocode"
          placeholder="Promocode"
        />
      </b-form-group>
    </b-col>

    <!-- Bines -->
    <b-col cols="12" v-if="loadingBines && reporte.tipoPromo === 'PROTA'">
      <b-spinner small label="Loading..." />
    </b-col>
    <b-col cols="12" v-if="!loadingBines && reporte.tipoPromo === 'PROTA'">
      <b-form-group label="Banco" label-for="v-banco">
        <v-select
          id="v-banco"
          label="title"
          v-model="banco"
          :reduce="(b) => b.title"
          :options="bancos"
          :clearable="false"
          @option:selected="onchangeBanco"
        />
      </b-form-group>

      <b-form-group v-if="banco" label="Tarjeta" label-for="v-tarjeta">
        <v-select
          id="v-tarjeta"
          label="title"
          v-model="tarjeta"
          :reduce="(t) => t.title"
          :options="tarjetas"
          :clearable="false"
          @option:selected="onchangeTarjeta"
        />
      </b-form-group>
      <b-form-group v-if="tarjeta" label="Bines" label-for="v-bines">
        <v-select
          id="vi-bines"
          label="biBin"
          v-model="reporte.bin"
          :reduce="(b) => b.biBin"
          :options="mBines"
          :clearable="false"
          :filterable="true"
          @option:selected="onChangeReport"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-1">
      <b-button
        block
        @click="generateReport"
        variant="primary"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>{{ btnText }}</div>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadioGroup,
  },
  data() {
    return {
      url: null,
      bines: [],
      tipos: [
        { text: "PromoCode", value: "PROCO" },
        { text: "PromoTarjeta", value: "PROTA" },
      ],
      banco: "",
      tarjeta: "",
      reporte: null,
      loading: false,
      loadingBines: false,
      loadingTiendas: false,
    };
  },
  beforeMount() {
    this.reporte = {
      bin: "",
      factura: false,
      fechaFin: null,
      promocode: "12345",
      tipoPromo: "PROCO",
      fechaInicio: null,
      tipoComercio: "MARKET",
    };
  },
  mounted() {
    this.loadBines();
  },
  methods: {
    onChangeReport() {
      this.url = null;
    },
    onChangeType(e) {
      this.url = null;
      this.reporte.bin = "";
      this.reporte.promocode = "";
    },
    onchangeBanco() {
      this.url = null;
      this.tarjeta = null;
      this.reporte.bin = null;
    },
    onchangeTarjeta() {
      this.url = null;
      this.reporte.bin = null;
    },
    loadBines() {
      this.loadingBines = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.UrlMiddlewareGET, {
            path: "/bine/obtenertodos",
            body: JSON.stringify({ tokenSesion: getAuthToken() }),
          })
          .then((res) => {
            this.bines = res.data;
            resolve();
          })
          .catch((e) => {
            reject(e.message);
          })
          .finally(() => {
            this.loadingBines = false;
          });
      });
    },
    generateReport(event) {
      event.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Reportes/Promociones",
          body: JSON.stringify({
            ...this.reporte,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            if (res.data.url === null)
              throw new Error(
                "El reporte seleccionado no genero datos, intente con otra configuracion"
              );
            this.url = res.data.url;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se genero correctamente el Reporte.",
                title: "Exito",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al generar el Reporte [${e.message}].`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    btnText() {
      return this.url ? "Descargar Reporte" : "Generar Reporte";
    },
    bancos() {
      const bancos = [];
      this.bines.forEach((b) => {
        if (!bancos.some((bb) => bb.title === b.biBanco))
          bancos.push({ title: b.biBanco });
      });
      return bancos;
    },
    tarjetas() {
      const tarjetas = [];
      if (this.banco)
        this.bines
          .filter((b) => b.biBanco === this.banco)
          .forEach((b) => {
            if (!tarjetas.some((bb) => bb.title === b.biDescripcion))
              tarjetas.push({ title: b.biDescripcion });
          });
      return tarjetas;
    },
    mBines() {
      let bines = [];
      if (this.tarjeta)
        bines = this.bines.filter((b) => b.biDescripcion === this.tarjeta);
      return bines;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>